import KeycloakPlugin from './plugins/keycloak';

(async function main() {
  const kauth = new KeycloakPlugin({
    realm: 'meerkat',
    url: 'https://accounts.morgansolar.com/auth',
    clientId: 'daq-portal'
  });

  import('./app').then(({ default: init }) => init(kauth));

  try {
    const loggedin = await kauth.init({
      onLoad: 'login-required',
      promiseType: 'native',
      checkLoginIframe: false
    });

    if (loggedin) {
      kauth.onRefreshTokenExpired = () => {
        kauth.login();
      };
    }
  } catch (err) {
    if (err instanceof TypeError) {
      if (err.message.includes('kc.login')) {
        // Ignore Keycloak bug
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
}());
